import React from 'react';

export const HomeIcon = () => {
  return (
    <svg
      className="nav-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18.665"
      viewBox="0 0 20 18.665"
    >
      <path
        fill="currentColor"
        d="M20.9,11.7a1.477,1.477,0,0,1-1.393.968H19V20a.667.667,0,0,1-.667.667h-4V16a3.333,3.333,0,0,0-6.666,0v4.666h-4A.667.667,0,0,1,3,20V12.667H2.49a1.492,1.492,0,0,1-.98-2.614L9.3,2.68a2.479,2.479,0,0,1,3.408,0l7.8,7.39A1.469,1.469,0,0,1,20.9,11.7Z"
        transform="translate(-0.999 -2.001)"
      />
    </svg>
  );
};

export const LoanIcon = () => {
  return (
    <svg
      className="nav-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M4.1,451.836a.586.586,0,0,0-.586-.586H.586a.586.586,0,0,0-.586.586v4.727a.586.586,0,0,0,.586.586h2.93a.586.586,0,0,0,.586-.586Zm0,0"
        transform="translate(0 -437.148)"
      />
      <path
        fill="currentColor"
        d="M173.516,338.75h-2.93a.586.586,0,0,0-.586.586v8.242a.586.586,0,0,0,.586.586h2.93a.586.586,0,0,0,.586-.586v-8.242A.586.586,0,0,0,173.516,338.75Zm0,0"
        transform="translate(-164.688 -328.164)"
      />
      <path
        fill="currentColor"
        d="M342.266,301.25h-2.93a.586.586,0,0,0-.586.586v9.414a.586.586,0,0,0,.586.586h2.93a.586.586,0,0,0,.586-.586v-9.414A.586.586,0,0,0,342.266,301.25Zm0,0"
        transform="translate(-328.164 -291.836)"
      />
      <path
        fill="currentColor"
        d="M512.266,151.25h-2.93a.586.586,0,0,0-.586.586v14.1a.586.586,0,0,0,.586.586h2.93a.586.586,0,0,0,.586-.586v-14.1A.586.586,0,0,0,512.266,151.25Zm0,0"
        transform="translate(-492.852 -146.523)"
      />
      <path
        fill="currentColor"
        d="M12.383,12.891a1.76,1.76,0,0,0,1.758-1.758,1.733,1.733,0,0,0-.073-.472l2.283-1.612a1.745,1.745,0,0,0,2.746-1.2l2.338-.492a1.753,1.753,0,0,0,3.121-1.575l2.8-2.493a1.737,1.737,0,0,0,.843.225,1.765,1.765,0,1,0-1.626-1.1l-2.8,2.493a1.737,1.737,0,0,0-.843-.225A1.754,1.754,0,0,0,21.2,6.212L18.858,6.7a1.753,1.753,0,0,0-3.252.913,1.733,1.733,0,0,0,.073.472L13.4,9.7a1.756,1.756,0,1,0-1.012,3.19Zm0,0"
        transform="translate(-10.293)"
      />
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg
      className="nav-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="15.751"
      height="18.738"
      viewBox="0 0 15.751 18.738"
    >
      <circle
        fill="currentColor"
        cx="4.345"
        cy="4.345"
        r="4.345"
        transform="translate(3.53)"
      />
      <path
        fill="currentColor"
        d="M28.5,150a7.876,7.876,0,0,0-7.876,7.876H36.376A7.876,7.876,0,0,0,28.5,150Z"
        transform="translate(-20.625 -139.137)"
      />
    </svg>
  );
};

export const MoreIcon = () => {
  return (
    <svg
      className="nav-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18.72"
      height="4.32"
      viewBox="0 0 18.72 4.32"
    >
      <g transform="translate(18.72) rotate(90)">
        <path
          fill="currentColor"
          d="M13,15.16A2.16,2.16,0,1,0,15.16,13,2.163,2.163,0,0,0,13,15.16Z"
          transform="translate(-13 -5.8)"
        />
        <path
          fill="currentColor"
          d="M13,25.16A2.16,2.16,0,1,0,15.16,23,2.163,2.163,0,0,0,13,25.16Z"
          transform="translate(-13 -8.6)"
        />
        <path
          fill="currentColor"
          d="M13,5.16A2.16,2.16,0,1,0,15.16,3,2.163,2.163,0,0,0,13,5.16Z"
          transform="translate(-13 -3)"
        />
      </g>
    </svg>
  );
};
