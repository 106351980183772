import React from 'react';
import { Link } from 'react-router-dom';
import ButtonIos from 'assets/images/download-button-app-store.svg';

const DownloadButtonApple = () => (
  <Link to="/">
    <img src={ButtonIos} alt="Download on the App Store" />
  </Link>
);

export default DownloadButtonApple;
