import React from 'react';

import ApplyLoan from 'assets/images/apply-loan.svg';
import GetVerified from 'assets/images/get-verified.svg';
import Approved from 'assets/images/approved.svg';

// const howItWorks = [
//   {
//     img: ApplyLoan,
//     title: 'Apply for a Loan',
//     details: 'Make sure to submit all pertinent requirements.',
//   },
//   {
//     img: GetVerified,
//     title: 'Get Verified',
//     details: 'APPEND will verify your identity and membership.',
//   },
//   {
//     img: Approved,
//     title: 'You’re Approved!',
//     details: 'You will be notified of the status of your loan application.',
//   },
// ];

const howItWorks = [
  {
    img: ApplyLoan,
    title:
      'Apply for a microfinance loan  from your MFI. Get ready with your MFI ID directly given to you by your MFI',
    details:
      'Make sure that you have submitted all your pertinent and physical requirements to your MFI prior to onboarding  the AppendPay app. Encode to the app the loan application information.',
  },
  {
    img: GetVerified,
    title: 'Get Verified',
    details:
      'AppendPay system will verify your identity and membership with your MFI.',
  },
  {
    img: Approved,
    title: 'You’re Approved!',
    details:
      'You will be notified through the app of the status of your microfinance loan application from your MFI.',
  },
];

function HowItWorksSection() {
  return (
    <div id="getting-started" className="mt-8 px-responsive md:py-8 lg:py-20">
      <div className="text-center">
        <p className="font-semibold text-gray-300">
          {/* EMPOWERING MICRO AND SMALL ENTERPRISES */}
          Empowering the micro and small enterprises&apos; sector through
          digitalization
        </p>

        <h2 className="text-4xl text-primary-800 font-bold mt-4">
          {/* How <span className="text-secondary-800">A</span>ppendPay Works */}
          How <span className="text-secondary-800">A</span>ppendPay Works for
          Microfinance Clients
        </h2>

        <p className="mt-4 text-gray-500">
          {/* Get your much-needed financing in three easy steps! */}
          Get your much-needed microfinance in three easy steps!
        </p>
      </div>

      <div className="flex flex-col items-center justify-center md:flex-row md:items-start">
        {howItWorks.map((how) => (
          <div
            key={how.title}
            className="mt-5 flex flex-col items-center mx-2 flex-1"
          >
            <img src={how.img} alt={how.title} />

            <h3 className="mt-5 text-primary-800 text-xl font-bold text-center">
              {how.title}
            </h3>

            <p className="mt-4 text-gray-500 text-center">{how.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowItWorksSection;
