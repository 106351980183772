/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiLoading } from 'react-reqq';

import { useAccountAttributes } from 'modules/auth/hooks';
import { useUploadCare } from 'modules/common/hooks';
import IdCardIcon from 'assets/images/id-card.svg';

import * as c from '../constants';
import * as actions from '../actions';

function FirstStep() {
  const history = useHistory();
  const [userInfo] = useAccountAttributes();
  const [form, setForm] = useState({});
  const [idList, setIdList] = useState([]);
  const isLoadingList = useApiLoading(c.VERIFICATION_ID_LIST, 'get');
  const isLoadingPost = useApiLoading(c.VERIFICATION_FIRST, 'post');
  const [open, info, clear] = useUploadCare({
    crop: '5:3',
    imageOnly: true,
  });

  const handleClearSource = (e) => {
    e.stopPropagation();
    clear();
  };

  const handleSelectedId = (e) => {
    const { value } = e.target;
    setForm((prev) => ({ ...prev, ...JSON.parse(value) }));
  };

  const handlePostStepOne = () => {
    actions.postFirstStep(userInfo._user_id, form, () => {
      history.push('/verification/step/2');
    });
  };

  useEffect(() => {
    actions.listValidId((list) => setIdList(list));
  }, []);

  useEffect(() => {
    setForm((prev) => ({ ...prev, file: info.source }));
  }, [info.source]);

  return (
    <div
      className="col-12 col-sm-7 col-md-5 col-lg-4 d-flex flex-column
     justify-content-center align-items-center py-5 py-sm-3"
    >
      <div className="floating-form select w-100">
        <select
          id="kyc_id"
          className="floating-input form-control"
          onChange={handleSelectedId}
        >
          {isLoadingList ? (
            <option> Loading Types... </option>
          ) : (
            <option value={JSON.stringify({ requirement_id: '', name: '' })}>
              {' '}
              Select ID{' '}
            </option>
          )}
          {idList.map(({ ID, Description }) => (
            <option
              key={ID}
              value={JSON.stringify({
                requirement_id: ID,
                name: Description,
              })}
            >
              {Description}
            </option>
          ))}
        </select>

        <label
          htmlFor="kyc_id"
          className="floating-label"
          data-content="ID Type"
        >
          <span className="hidden -visually">ID Type</span>
        </label>
      </div>

      <h3 className="font-weight-bold mt-3 align-self-start">Upload ID</h3>
      <div className="upload-id" onClick={open}>
        <div className="box">
          <button
            className={`btn close-btn ${!info.source && 'd-none'}`}
            onClick={handleClearSource}
          >
            <i className="fa fa-times" />
          </button>
          {info.source ? (
            <img alt="" src={info.source} />
          ) : (
            <>
              <img alt="" src={IdCardIcon} />
              <small className="mt-2">
                {info.status === 'uploading'
                  ? 'Uploading...'
                  : 'Scan Any Valid ID'}
              </small>
            </>
          )}
        </div>
      </div>
      <button
        type="button"
        className="btn btn-blue-gray cta-btn btn-lg mt-5 w-75"
        disabled={info.status === 'uploading' || isLoadingPost}
        onClick={info.source ? handlePostStepOne : open}
      >
        {info.source ? 'Proceed' : 'Upload ID'}
      </button>
    </div>
  );
}

export default FirstStep;
