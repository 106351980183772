import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'assets/images/logo-appendpay-white.svg';

const Footer = () => {
  return (
    <footer className="px-responsive py-8 flex flex-col md:flex-row md:items-center text-white">
      <img src={Logo} alt="AppendPay" className="w-28" />

      <div className="md:ml-auto">
        <Link to="/docs/privacy-policy" className="text-white">
          Privacy Policy
        </Link>
      </div>
      <div className="md:ml-4">
        <Link to="/docs/terms-and-conditions" className="text-white">
          Terms & Conditions
        </Link>
      </div>

      <small className="text-xs py-3 md:ml-4">
        &copy; {new Date().getFullYear()} AppendPay
      </small>
    </footer>
  );
};

export default Footer;
