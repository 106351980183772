import React, { useState, useRef } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import Header from 'modules/common/components/Header';
import HeroSection from 'modules/landing-page/components/HeroSection';
import Footer from 'modules/common/components/Footer';
import HeroImg from 'assets/images/promotionals/hero.png';

import Documents from 'modules/documents';
import LandingPage from 'modules/landing-page';
// import RegisterPage from 'modules/register-page';
// import Login from 'modules/auth/containers/Login';
// import RegisterSteps from 'modules/register-page/components/RegisterSteps';

function Public() {
  const history = useHistory();
  const scrollContainer = useRef();
  const [scrollIsOnTop, setScrollIsOnTop] = useState(true);

  const accountAttri = useSelector((state) =>
    get(state, 'api.AUTHENTICATION.accountAttri', false)
  );
  if (accountAttri) {
    let currentStep;
    if (accountAttri.set_mpin === 0) {
      currentStep = 2;
    } else if (accountAttri.set_photo === 0) {
      currentStep = 3;
    }
    history.push(`/register/step/${currentStep}`, {
      resInfo: { attributes: { ...accountAttri } },
    });
  }

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (scrollTop > 15) {
      setScrollIsOnTop(false);
      return;
    }
    setScrollIsOnTop(true);
  };

  return (
    <div
      ref={scrollContainer}
      className="bg-image-primary bg-no-repeat w-full max-h-screen overflow-scroll relative"
      onScroll={handleScroll}
    >
      <Header scrollRef={scrollContainer} scrollIsOnTop={scrollIsOnTop} />
      <Route
        exact
        path={['/', '/register', '/login']}
        render={() => {
          return (
            <div className="py-8 px-responsive flex flex-col md:flex-row">
              <Route
                exact
                path="/"
                render={() => (
                  <div className="basis-full md:flex md:items-center">
                    <HeroSection />
                  </div>
                )}
              />
              <div className="flex-shrink-0 w-full md:w-1/2">
                <img
                  className="w-full"
                  src={HeroImg}
                  alt="AppendPay Screenshot"
                />
              </div>
              {/* <Route
                path="/register"
                render={() => (
                  <div className="basis-full md:px-10 lg:px-20 flex items-center">
                    <RegisterPage />
                  </div>
                )}
              /> */}
              {/* <Route
                path="/login"
                render={() => (
                  <div className="basis-full md:px-10 lg:px-20 flex items-center">
                    <Login />
                  </div>
                )}
              /> */}
            </div>
          );
        }}
      />

      <div className="bg-white">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/docs" component={Documents} />
          {/* <Route exact path="/register/step/:id" component={RegisterSteps} /> */}
          {/* <Route
            exact
            path="/register/step"
            render={() => <Redirect to="/register/step/1" />}
          /> */}
          <Route exact path="/logout" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default Public;
