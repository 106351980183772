import React, { useEffect, useState } from 'react';
import marked from 'marked';

const TermsAndConditions = () => {
  const [rawMarkUp, setMarkUp] = useState('');
  const getMdFile = () => {
    fetch('/terms_conditions.md')
      .then((res) => res.text())
      .then((text) => {
        setMarkUp(marked(text));
      });
  };

  useEffect(() => {
    getMdFile();
  }, []);

  return (
    <div className="px-responsive py-8">
      <div dangerouslySetInnerHTML={{ __html: rawMarkUp }} />
    </div>
  );
};

export default TermsAndConditions;
