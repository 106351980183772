import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  HomeIcon,
  LoanIcon,
  MoreIcon,
  ProfileIcon,
} from 'modules/common/components/NavIcons';
import GenericAvatar from 'assets/images/square-avatar.svg';

function NavigationBar() {
  return (
    <div className="navigation-bar">
      <div className="hidden px-6 py-8 lg:flex items-center">
        <img className="w-10 h-10 rounded-full" src={GenericAvatar} />
        <p className="ml-4">Taylor Swift</p>
      </div>
      <nav className="navigation-items">
        <NavLink exact to="/" className="navigation-item">
          <HomeIcon />
          <label className="lg:ml-2">Home</label>
        </NavLink>

        <NavLink exact to="/loans" className="navigation-item">
          <LoanIcon />
          <label className="lg:ml-2">Loans</label>
        </NavLink>

        <div className="lg:hidden">
          <NavLink exact to="/qrcode" className="navigation-item">
            <span className="w-12 h-12 bg-yellow-400 rounded-full grid place-items-center relative -top-8">
              <i className="fa fa-qrcode text-white" />
            </span>
          </NavLink>
        </div>

        <NavLink exact to="/profile" className="navigation-item">
          <ProfileIcon />
          <label className="lg:ml-2">Profile</label>
        </NavLink>

        <NavLink exact to="/more" className="navigation-item">
          <MoreIcon />
          <label className="lg:ml-2">More</label>
        </NavLink>
      </nav>
    </div>
  );
}

export default NavigationBar;
