import React from 'react'; // , { useEffect }
import { Route, Redirect, Switch } from 'react-router-dom';
import { req } from 'react-reqq';

// import { showModal } from 'modules/common/components/AlertModal';
// import WorkInProgress from 'modules/common/modals/WorkInProgress';
import { useAccountAttributes } from 'modules/auth/hooks';

import PrivateHeader from 'modules/partial/components/PrivateHeader';
import Logout from 'modules/auth/containers/Logout';
import DashboardContainer from 'modules/dashboard/containers/DashboardContainer';
import VerificationPage from 'modules/verification-page';
import NavigationBar from 'modules/partial/components/NavigationBar';
import Footer from 'modules/common/components/Footer';

const Private = () => {
  // const history = useHistory();

  const [accountAttri, isAccountReady] = useAccountAttributes();

  if (!isAccountReady) {
    req.set('AUTHENTICATION', { isAuthenticated: false, accountAttri });
  }

  return (
    <div className="flex flex-col w-full h-screen max-h-screen">
      <PrivateHeader />
      <div className="flex-grow flex overflow-scroll">
        <NavigationBar />
        <main className="w-full h-full overflow-y-scroll lg:bg-gray-50 lg:p-8 lg:rounded-tl-3xl">
          <Switch>
            <Route exact path="/" component={DashboardContainer} />
            <Route path="/loans" render={() => <div>loans</div>} />
            <Route path="/settings" render={() => <div>settings</div>} />
            <Route path="/lead-train" render={() => <div>lead & train</div>} />
            {accountAttri.status === null && (
              <>
                <Route
                  exact
                  path="/verification/step/:id"
                  component={VerificationPage}
                />
                <Route
                  path="/verification"
                  render={() => <Redirect to="/verification/step/1" />}
                />
              </>
            )}
            {/* <Route render={() => <Redirect to="/" />} /> */}
          </Switch>
        </main>
      </div>
      <Route exact path="/logout" component={Logout} />

      {/* <Footer /> */}
    </div>
  );
};

export default Private;
