import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';

import Stepper from 'modules/common/components/Stepper';

import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';

function VerificationPage() {
  const history = useHistory();
  const { id } = useParams();

  const handlePrev = () => {
    const currStepId = parseInt(id, 10);
    if (currStepId === 1) {
      history.push('/');
    } else if (currStepId === 2) {
      history.push('/verification/step/1');
    }
  };

  const stepLabels = ['Upload ID', 'Add Information'];

  return (
    <div
      className="container-fluid d-flex flex-column align-items-center position-relative"
      style={{ backgroundColor: '#FFF' }}
    >
      <button
        type="button"
        className="btn position-absolute"
        style={{ left: '10px', top: '40px' }}
        onClick={handlePrev}
      >
        <i
          className="fa fa-arrow-left text-primary"
          style={{ fontSize: '30px' }}
        />
      </button>
      <h3 className="mt-5 font-weight-bold">Get Verified</h3>
      <div className="my-4">
        <Stepper labels={stepLabels} activeStep={parseInt(id, 10)} />
      </div>

      <Switch>
        <Route path="/verification/step/1" component={FirstStep} />
        <Route path="/verification/step/2" component={SecondStep} />
        <Route render={() => <Redirect to="/verification/step/1" />} />
      </Switch>
    </div>
  );
}

export default VerificationPage;
