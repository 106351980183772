import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { req, useApiGet } from 'react-reqq';
import Cookie from 'js-cookie';
import _ from 'lodash';

import * as c from './constants';

export const foo = () => {};

export const useAuth = () => {
  const auth = useSelector(
    (state) => _.get(state, `api.${c.AUTHENTICATION}`) || {}
  );

  useEffect(() => {
    if (!auth.accountAttri) {
      Cookie.remove('_token');
    }
    const token = Cookie.get('_token');
    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token && !!auth.accountAttri,
    });
  }, []);
  return ['foo', auth.isAuthenticated];
};

export const useAccountAttributes = () => {
  const { accountAttri } = useApiGet(c.AUTHENTICATION, {});
  const isAccountReady = !!(
    _.get(accountAttri, 'set_mpin', 0) && _.get(accountAttri, 'set_photo', 0)
  );

  return [accountAttri || {}, true];
};
