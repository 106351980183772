/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiLoading } from 'react-reqq';
import moment from 'moment';

import FormInput from 'modules/common/forms/FormInput';
import { useAccountAttributes } from 'modules/auth/hooks';

import * as c from '../constants';
import * as actions from '../actions';

function SecondStep() {
  const history = useHistory();
  const [userInfo] = useAccountAttributes();
  const [form, setForm] = useState({
    status: 'PENDING',
  });
  const [formErr, setFormErr] = useState({
    bd: '',
  });

  const isLoadingPut = useApiLoading(c.VERIFICATION_SECOND, 'put');

  const handleSubmitInfo = (e) => {
    e.preventDefault();
    actions.putSecondStep(userInfo.profile_id, form, () => {
      history.push('/');
    });
  };

  const handleBirthdateCheck = (e) => {
    if (!e.target.value) {
      setFormErr((errs) => ({ ...errs, bd: '' }));
      return;
    }
    const birtdate = moment(e.target.value, 'MM/DD/YYYY', true);
    const age = moment().diff(birtdate, 'years');

    setFormErr((errs) => {
      if (age < 18) {
        return {
          ...errs,
          bd: 'You need to be at least 18 years old',
        };
      } else if (isNaN(age)) {
        return {
          ...errs,
          bd: 'Please enter the correct date format',
        };
      } else {
        return { ...errs, bd: '' };
      }
    });
  };

  return (
    <form
      className="col-12 col-sm-7 col-md-5 col-lg-4 d-flex flex-column
     justify-content-center align-items-center py-5 py-sm-3"
      onSubmit={handleSubmitInfo}
    >
      <h3 className="font-weight-bold align-self-start">
        Personal Information
      </h3>
      <div className="mt-2 w-100">
        <FormInput
          id="first_name"
          label="First Name"
          form={form}
          setForm={setForm}
        />
      </div>
      <div className="mt-2 w-100">
        <FormInput
          id="middle_name"
          label="Middle Name (Optional)"
          form={form}
          setForm={setForm}
        />
      </div>
      <div className="mt-2 w-100">
        <FormInput
          id="last_name"
          label="Last Name"
          form={form}
          setForm={setForm}
        />
      </div>
      <div className="mt-2 w-100">
        <FormInput
          id="birth_date"
          label="Birthdate"
          placeholder="MM/DD/YYYY"
          form={form}
          setForm={setForm}
          onBlur={handleBirthdateCheck}
          error={formErr.bd}
        />
      </div>
      <h3 className="mt-4 font-weight-bold align-self-start">Home Address</h3>
      <div className="mt-2 w-100">
        <FormInput
          id="address"
          label="House Number, Building and Street Name"
          form={form}
          setForm={setForm}
        />
      </div>
      <div className="mt-2 w-100">
        <FormInput
          id="barangay"
          label="Barangay"
          form={form}
          setForm={setForm}
        />
      </div>
      <div className="mt-2 w-100">
        <FormInput
          id="city"
          label="Municipality"
          form={form}
          setForm={setForm}
        />
      </div>
      <div className="mt-2 w-100">
        <FormInput
          id="province"
          label="Province"
          form={form}
          setForm={setForm}
        />
      </div>
      <div className="mt-2 w-100">
        <FormInput
          id="zip_code"
          label="Zip Code"
          form={form}
          setForm={setForm}
        />
      </div>
      <button
        type="submit"
        className="btn btn-primary cta-btn btn-lg mt-5 w-100"
        disabled={isLoadingPut}
      >
        Submit
      </button>
    </form>
  );
}

export default SecondStep;
