import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import Logo from 'assets/images/logo-appendpay-white.svg';
// import { showModal } from 'modules/common/components/AlertModal';
// import WorkInProgress from 'modules/common/modals/WorkInProgress';

function Header({ scrollRef, scrollIsOnTop }) {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const hasHash = useMemo(() => !!location?.hash, [location]);

  // const handleCommingSoon = () => {
  //   showModal({
  //     title: false,
  //     size: 'modal-lg',
  //     content: (close) => (
  //       <WorkInProgress
  //         onClose={close}
  //         title="Oops.. We are still working on this feature."
  //         message="Thank you for your understanding..."
  //       />
  //     ),
  //   });
  // };

  const scrollWidthOffset = (el, offset) => {
    const yCoordinate =
      el.getBoundingClientRect().top + scrollRef.current.scrollTop;
    scrollRef.current.scroll({
      top: yCoordinate + offset,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`sticky top-0 w-full py-4 px-responsive z-50 ${
        !scrollIsOnTop ? 'bg-gradient-to-r from-primary-800 to-purple-500' : ''
      }`}
    >
      <header className="header public">
        <img src={Logo} alt="AppendPay" className="navbar-brand" />
        <div className="ml-auto relative">
          <button
            className={`${
              !show ? 'text-white' : 'text-gray-700'
            } text-3xl relative z-10 md:hidden`}
            type="button"
            onClick={() => setShow(!show)}
          >
            <i className={`fas${!show ? ' fa-bars' : ' fa-times'}`} />
          </button>
          <nav
            className={`${
              !show ? 'hidden md:block' : ''
            } fixed top-0 left-0 bg-white bg-opacity-95 w-full h-full md:static md:bg-transparent`}
          >
            <nav className="navigation">
              <NavHashLink
                className={`nav-item ${!hasHash ? 'active' : ''}`}
                exact
                to="/#top"
                scroll={() =>
                  scrollRef.current.scroll({ top: 0, behavior: 'smooth' })
                }
              >
                Home
              </NavHashLink>
              <NavHashLink
                smooth
                className="nav-item"
                to="/#getting-started"
                scroll={(el) => scrollWidthOffset(el, -100)}
              >
                Getting Started
              </NavHashLink>
              <NavHashLink
                smooth
                className="nav-item"
                to="/#services"
                scroll={(el) => scrollWidthOffset(el, -70)}
              >
                Services
              </NavHashLink>
              {/* <li className="nav-item">
              <p //eslint-disable-line
                className="nav-link text-white mr-3"
                onClick={handleCommingSoon}
              >
                Help Center
              </p>
            </li> */}
              {/* <span className="mt-4 md:ml-5 md:mt-0">
              <Route
                exact
                path={['/', '/login']}
                render={() => (
                  <NavLink className="btn register-button w-48" to="/register">
                    Create an account
                    <div className="btn hover-effect secondary">Register</div>
                  </NavLink>
                )}
              />
              <Route
                path={['/register']}
                render={() => (
                  <NavLink
                    className="btn secondary font-semibold w-48"
                    to="/login"
                  >
                    Login
                  </NavLink>
                )}
              />
            </span> */}
            </nav>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
