import React from 'react';

import DownloadButtonAndroid from './DownloadButtonAndroid';
import DownloadButtonApple from './DownloadButtonApple';

const HeroSection = () => {
  return (
    <div className="mt-12 md:mt-0">
      <h1 className="font-bold text-4xl text-white lg:text-5xl lg:leading-tight">
        {/* Make fast and secure loan and mobile payments with AppendPay */}
        Make fast and secure mobile payments with AppendPay
      </h1>
      <p className="mt-4 text-white">
        {/* AppendPay is the next generation mobile application and online system
        that links member- Microfinance Institutions around the country to a
        wide array of microfinance platforms and eCommerce channels. */}
        AppendPay is a digital economt ecosystem mobile application and payment
        system that that links microfinance institutions (MFIs) to their clients
        around the country. Offers a wide array of eCommerce channels to MFIs
        and micro, small and medium scale entrepreneurs.
      </p>
      <div className="flex mt-7">
        <button>
          <DownloadButtonAndroid />
          <p className="font-semibold text-white opacity-25">Download Now</p>
        </button>

        <button className="ml-3">
          <DownloadButtonApple />
          <p className="font-semibold text-white opacity-25">Coming soon</p>
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
