import React from 'react';

import LoanOnePlace from 'assets/images/promotionals/loan-in-one-place.png';
import AndroidScreen from 'assets/images/android-screen.png';
import iOSScreen from 'assets/images/promotionals/ios-screen.png';

import DownloadButtonAndroid from './components/DownloadButtonAndroid';
import DownloadButtonApple from './components/DownloadButtonApple';

import FeatureSection from './components/FeatureSection';
import HowItWorksSection from './components/HowItWorksSection';

function LandingPage() {
  return (
    <div>
      <div className="flex flex-col md:flex-row px-responsive py-10 relative">
        <img
          className="absolute w-32 opacity-40 md:w-1/3 md:block md:opacity-100 md:static"
          src={LoanOnePlace}
          // alt="Your loan in one place"
          alt="Your One Stop Service 
          Platform"
        />

        <div className="relative z-10 mt-5 px-responsive basis-full md:px-0 md:flex md:flex-col md:justify-center md:ml-20">
          <h2 className="text-primary-800 font-bold text-4xl md:text-5xl">
            {/* Your loan in one place */}
            Your One Stop Service Platform
          </h2>

          <p className="mt-8 text-gray-500">
            {/* Next generation loan and mobile payments, anytime and anywhere
            Easily manage your credits. AppendPay allows the app user to request
            and pay for various loans, utilities and commercial services. Users
            can also transfer money to other AppendPay users. */}
            Next generation microfinance products and mobile payments, anytime
            and anywhere .Easily manage your funds. AppendPay allows the app
            user to pay for various loans from their microfinance institutions
            and utilities and commercial services. Users can also transfer money
            to other AppendPay users. More financial inclusion features are
            ready to serve you!
          </p>
        </div>
      </div>

      <FeatureSection />

      <HowItWorksSection />

      <div className="mt-8 px-8 pt-8 px-responsive bg-gray-100 flex flex-col md:flex-row md:justify-around">
        <div className="flex flex-col items-center md:w-2/5 space-y-4">
          <div className="font-semibold text-lg text-gray-800 opacity-30">
            Download
          </div>
          <h2 className="text-primary-800 text-4xl font-bold">Android</h2>
          <p className="text-gray-500 text-center">
            Download AppendPay and apply for a loan today!
          </p>
          <DownloadButtonAndroid />
          <p className="font-semibold text-gray-500 opacity-25">Download now</p>
          <img src={iOSScreen} alt="AppendPay screenshot on Android phone" />
        </div>

        <div className="flex flex-col items-center md:w-2/5 space-y-4">
          <div className="font-semibold text-lg text-gray-800 opacity-30">
            Download
          </div>
          <h2 className="text-primary-800 text-4xl font-bold">iOS</h2>
          <p className="text-gray-500 text-center">
            Download AppendPay and apply for a loan today!
          </p>
          <DownloadButtonApple />
          <p className="font-semibold text-gray-500 opacity-25">Coming soon</p>
          <img src={iOSScreen} alt="AppendPay screenshot on iPhone" />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
