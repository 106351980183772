import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'setup/store';
import history from 'setup/history';
import App from './App';

// import 'assets/styles/styles.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/styles/styles.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
