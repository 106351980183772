import { useEffect } from 'react';
import * as actions from '../actions';

const Logout = () => {
  useEffect(() => {
    actions.logout();
  }, []);

  return null;
};

export default Logout;
