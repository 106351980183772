import React, { useState } from 'react';
import PropTypes from 'prop-types';

function FormInput({
  name,
  label,
  required,
  type,
  validation,
  error,
  leftIcon,
  rightIcon,
  ...props
}) {
  const [labelIsOnTop, setLabelIsOnTop] = useState(false);

  const handleFocus = () => {
    setLabelIsOnTop(true);
  };

  const handleBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      setLabelIsOnTop(false);
    }
  };

  return (
    <div className="flex items-center relative w-full">
      {label && (
        <label
          htmlFor={name}
          className={`absolute ml-3 top-1/2 text-gray-600 font-medium 
                     transition-all duration-75 ease-in pointer-events-none
                     `}
          style={{
            transform: !labelIsOnTop
              ? 'translateY(-50%) scale(1)'
              : 'translate(-15%, -120%) scale(.7)',
          }}
        >
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <span className="absolute left-0 ml-4 ">{leftIcon}</span>
      <input
        type={type}
        ref={validation}
        name={name}
        className={`
        bg-white text-gray-900 w-full px-3 pb-2 pt-5 h-14 rounded-lg mb-0 bg-transparent font-medium outline-none
          ${error ? 'border-red-500' : ''} 
          ${rightIcon ? 'pr-10' : ''}  
          ${leftIcon ? 'pl-10' : "'"} 
        `}
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <span className="absolute px-4 right-0">{rightIcon}</span>
      {error && (
        <small className="text-xs text-red-500">
          <i className="fa fa-exclamation-circle" /> {error.message}
        </small>
      )}
    </div>
  );
}

export default FormInput;

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  validation: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.instanceOf(Object),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
};

FormInput.defaultProps = {
  label: '',
  error: null,
  required: false,
  type: 'text',
  leftIcon: null,
  rightIcon: null,
};
