import React from 'react';
import { Link } from 'react-router-dom';
import ButtonAndroid from 'assets/images/download-button-google-play.svg';

const DownloadButtonAndroid = () => (
  <Link
    to={{
      pathname:
        'https://play.google.com/store/apps/details?id=com.multisys.append',
    }}
    target="_blank"
  >
    <img src={ButtonAndroid} alt="Download app on Google Play" />
  </Link>
);

export default DownloadButtonAndroid;
