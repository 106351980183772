import React from 'react';
import ToastMarker from 'react-geek-toast';

import { useAuth } from 'modules/auth/hooks';
import { ModalMarker } from 'modules/common/components/AlertModal';

import Public from './Public';
import Private from './Private';

const App = () => {
  const [_, isAuthenticated] = useAuth();
  return (
    <>
      <ToastMarker />
      <ModalMarker />
      {isAuthenticated ? <Private /> : <Public />}
    </>
  );
};

export default App;
