import React from 'react';

function AccountBalance() {
  return (
    <div className="bg-white">
      <div className="bg-primary-800 bg-opacity-10 p-4">
        <div className="bg-white rounded-lg p-4 shadow">
          <div className="flex">
            <h4 className="text-xl font-thin flex items-center">
              Php <span className="ml-1 font-bold text-3xl">0.00</span>
            </h4>
            <button className="ml-auto h-7 flex items-center rounded-lg bg-primary-800 bg-opacity-10 text-primary-800">
              <span className="w-6 h-6 bg-gradient-to-br from-purple-300 to-primary-800 rounded-lg grid place-items-center">
                <i className="fa fa-plus text-white" />
              </span>
              <p className="mx-2 text-sm">Load Wallet</p>
            </button>
          </div>
          <p className="mt-2 text-primary-800 text-sm">Wallet Balance</p>
        </div>
      </div>
      <div className="p-4 border-b">
        <div className="p-4 rounded-lg text-white bg-gradient-to-br from-primary-800 to-primary-400">
          <h4 className="text-xl font-thin flex items-center">
            Php <span className="ml-1 font-bold text-2xl">0.00</span>
          </h4>
          <p className="mt-1 text-sm font-thin">Loan Balance</p>
        </div>
        <p className="mt-2 font-thin text-xs text-primary-800">
          Complete profile to access more services from AppendPay.
        </p>
        <button
          className="mt-4 w-full p-3 bg-gradient-to-b from-secondary-800 to-secondary-400
           grid place-items-center rounded-full hover:to-secondary-800"
        >
          <p className="text-white font-bold">Get Verified Now</p>
        </button>
        <p className="mt-4 text-center font-thin text-xs">
          You can recieve your loan trough your chosen <br /> MFI account.
        </p>
      </div>
    </div>
  );
}

export default AccountBalance;
