import { useState } from 'react';
import uploadcare from 'uploadcare-widget';

export const useUploadCare = ({ ...config }) => {
  const [source, setSource] = useState('');
  const [status, setStatus] = useState('');

  const handleUploaded = (uploading) => {
    uploading.progress((data) => {
      setStatus(data.state);
    });
    uploading.done((data) => {
      setSource(data.cdnUrl);
    });
  };

  const open = () => {
    uploadcare
      .openDialog(null, {
        publicKey: process.env.REACT_APP_UPLOADCARE_KEY,
        tabs: 'file url',
        effects: 'crop,rotate,mirror',
        previewStep: true,
        multiple: false,
        ...config,
      })
      .done(handleUploaded);
  };

  const clear = () => {
    setSource('');
  };

  return [open, { status, source }, clear];
};

export const foo = () => {};
