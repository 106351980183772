import React from 'react';
import { NavLink } from 'react-router-dom';

import PromotionalBanners from 'modules/partial/components/PromotionalBanners';

import CashInIcon from 'assets/images/features/icon-cash-in.svg';
import CashOutIcon from 'assets/images/features/icon-cash-out.svg';
import SendFundIcon from 'assets/images/features/icon-send-fund.svg';
import RecieveFundIcon from 'assets/images/features/icon-recieve-fund.svg';
import LoadMobileIcon from 'assets/images/features/icon-load-mobile.svg';
import PayBillIcon from 'assets/images/features/icon-pay-bills.svg';
import OrderIcon from 'assets/images/features/icon-order.svg';
import LeadTrainIcon from 'assets/images/features/icon-lead-train.svg';

import AccountBalance from '../components/AccountBalance';
import { AppIcon } from 'modules/common/components/AppIconLogos';
import FormInput from 'modules/common/forms/FormInput';

const FEATURES = [
  {
    label: 'Cash In',
    icon: CashInIcon,
  },
  {
    label: 'Cash Out',
    icon: CashOutIcon,
  },
  {
    label: 'Send Fund',
    icon: SendFundIcon,
  },
  {
    label: 'Recieve Fund',
    icon: RecieveFundIcon,
  },
  {
    label: 'Load Mobile',
    icon: LoadMobileIcon,
  },
  {
    label: 'Pay Bills',
    icon: PayBillIcon,
  },
  {
    label: 'Order',
    icon: OrderIcon,
  },
  {
    label: 'Lead & Train',
    icon: LeadTrainIcon,
  },
];

function DashboardContainer() {
  return (
    <div className="flex flex-col pb-20">
      <PromotionalBanners />
      <div className="flex flex-col lg:flex-row-reverse lg:mt-4">
        <AccountBalance />
        <div className="p-4 flex-grow bg-white lg:mr-4 lg:shadow-sm lg:rounded-xl">
          <h4 className="font-bold">Make it easier with AppendPay</h4>
          <div className="mt-4 grid gap-2 grid-cols-4 lg:grid-cols-8">
            {FEATURES.map((feature) => (
              <NavLink
                className="p-2 flex flex-col items-center rounded-md shadow cursor-pointer hover:shadow-lg"
                to="/"
              >
                <img src={feature.icon} />
                <label className="mt-2 text-sm text-center text-gray-700 cursor-pointer">
                  {feature.label}
                </label>
              </NavLink>
            ))}
          </div>
          <div className="text-primary-800">
            <AppIcon />
            <FormInput name="name" label="First Name" validation={() => {}} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardContainer;
