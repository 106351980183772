import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

// import Header from 'modules/common/Header';
// import Footer from 'modules/common/Footer';

import PrivacyPolicy from './components/privacy-policy';
import TermsAndConditions from './components/terms-conditions';

function Documents() {
  const match = useRouteMatch();
  return (
    <div className="document">
      <Switch>
        <Route
          path={`${match.path}/privacy-policy`}
          component={PrivacyPolicy}
        />
        <Route
          path={`${match.path}/terms-and-conditions`}
          component={TermsAndConditions}
        />
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/privacy-policy`} />}
        />
      </Switch>
    </div>
  );
}

export default Documents;
