import React from 'react';

// const features = [
//   'Borrow and withdraw money.',
//   'Pay and deposit money.',
//   'Transfer money to other AppendPay users.',
//   'Pay bills.',
//   'Top up mobile with prepaid load.',
//   'Donate .',
// ];

const features = [
  'Receive money from their microfinance institutions.',
  'Cash out and withdraw money through accredited payment channels and Point of Sale Devices.',
  'Pay their microfinance loans to their microfinance institutions.',
  'Send and Receive funds with other AppendPay users.',
  'Pay bills.',
  'Top up mobile with prepaid load.',
  'Donate to their own charities.',
  'Order and buy products from social entrepreneurs.',
  'Lead and Train. Be a great leader and successful entrepreneur with our tested values formation, leadership and enterprise training program.',
];

const FeatureSection = () => (
  <div id="services" className="bg-image-phones">
    <div className="bg-primary-800 bg-opacity-20 md:bg-opacity-0">
      <div className="bg-white bg-opacity-70 md:bg-opacity-10">
        <div className="py-8 px-responsive md:py-20">
          <div className="md:w-1/2">
            <h2 className="text-4xl font-bold text-primary-800 md:text-5xl">
              <span className="text-secondary-800">A</span>ppendPay Features
            </h2>

            <p className="mt-4 text-gray-500">
              {/* AppendPay is an online platform that seeks to ease business
              processes and create seamless transactions for micro, small,
              medium and all other social entrepreneurs (MSMSEs). */}
              AppendPay is a mobile payments systems registered with the Bangko
              Sentral ng Pilipinas as Operator of Payment systems (OPS).
              <br />
              <br />
              The AppendPay app seeks to ease the business processes and create
              seamless transactions for micro, small, medium and all other
              social entrepreneurs (MSMSEs) by linking them to their
              microfinance institutions and cooperatives. It also provides their
              families an alternative payment system for their e- commerce
              requirements.
            </p>

            <ul className="mt-5">
              {features.map((feature, i) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  className="text-gray-500 md:my-8"
                  key={`feature-${i}`}
                >
                  <span className="text-primary-800 text-3xl font-semibold mr-4">
                    {i + 1}.
                  </span>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureSection;
