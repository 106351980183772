import { configureApi } from 'react-reqq';
import { toastError } from 'react-geek-toast';
import Cookie from 'js-cookie';
import * as _ from 'lodash';

import history from 'setup/history';

const errorMsg = 'Something went wrong!';

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,

  requestHeaders: () => {
    const headers = {};
    const token = Cookie.get('_token');
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return headers;
  },
  onError: (res) => {
    if (!res || res.status === 0) {
      toastError('Unable to connect to server');
      return;
    }

    const response = _.get(res, 'response');
    const message = _.get(res, 'response.message');

    if (_.get(response, 'exp', '') === 'token expired') {
      toastError(_.get(response, 'exp', ''));
      history.push('/logout');
    }

    if (Array.isArray(response)) {
      toastError(_.get(response, '0.detail', errorMsg));
      return;
    }

    if (!message && typeof response === 'object') {
      const errors = _.get(response, 'errors');

      if (_.get(errors, '0.code') === 500) {
        // TODO: uncomment the line below if api is working correctly for now do nothing for 500 errors
        // toastError('Server error');
        return;
      }

      // display the first error only
      toastError(_.get(errors, '0.detail', errorMsg));
      return;
    }

    toastError(message || errorMsg);
  },
});

export default store;
