import { req } from 'react-reqq';
import Cookie from 'js-cookie';
import { toastSuccess } from 'react-geek-toast';
import * as _ from 'lodash';

import * as c from './constants';

export const login = (load) => {
  const payload = {
    ...load,
    mobile_number: load.mobile_number.replace(/^0|^\+63/, ''),
  };
  req.post({
    key: c.BORROWER_LOGIN,
    url: '/v1/authentication',
    payload,
    onSuccess: (res) => {
      // TODO actions after login
      toastSuccess(_.get(res, 'response.message') || 'Logged in!');
      const token = _.get(res, 'response.token');
      const accountAttri = {
        ..._.get(res, 'response.data.attributes'),
        ..._.get(res, 'response.included[0].attributes'),
      };
      Cookie.set('_token', token);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
        accountAttri,
      });
    },
  });
};

export const logout = () => {
  toastSuccess('Logged out!');
  Cookie.remove('_token');
  req.set(c.AUTHENTICATION, {
    isAuthenticated: false,
  });
};
