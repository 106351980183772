import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Stepper = ({ activeStep, labels = [] }) => {
  return (
    <div className="stepper">
      <div
        className="active-ring"
        style={{ transform: `translateX(${(activeStep - 1) * 80}px)` }}
      />
      {labels.map((label, i) => {
        return (
          <Fragment key={label}>
            <div className="step">
              <div className="node">
                {i + 2 <= activeStep ? <i className="fa fa-check" /> : i + 1}
              </div>
              <small className="label">{labels[i]}</small>
            </div>
            {i + 1 !== labels.length && <div className="connector" />}
          </Fragment>
        );
      })}
    </div>
  );
};

Stepper.defaultProps = {
  labels: [],
};

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  labels: PropTypes.instanceOf(Array),
};

export default Stepper;
