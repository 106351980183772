import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { useAccountAttributes } from 'modules/auth/hooks';
import {
  AppLogoPurple,
  AppLogoWhite,
} from 'modules/common/components/AppIconLogos';

const PrivateHeader = () => {
  const [accountAttri] = useAccountAttributes();
  const isLg = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  return (
    <header className="sticky top-0 flex items-center px-4 py-4 lg:py-6 shadow bg-white lg:bg-gradient-to-r lg:from-primary-800 lg:to-primary-400 ">
      {isLg ? (
        <AppLogoWhite className="w-28" />
      ) : (
        <AppLogoPurple className="w-20" />
      )}
      <span className="ml-auto w-8 h-8 grid place-items-center rounded-full lg:bg-white">
        <i className="far fa-bell text-gray-800 lg:text-primary-800" />
      </span>
    </header>
  );
};

export default PrivateHeader;
