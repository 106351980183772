import { req } from 'react-reqq';
import { toastSuccess } from 'react-geek-toast';

import * as c from './constants';

export const listValidId = (callback) => {
  req.get({
    key: c.VERIFICATION_ID_LIST,
    url: '/v1/tski/identification_cards',
    onSuccess: ({ response: { data } }) => {
      callback(data.attributes.Table);
    },
  });
};

export const postFirstStep = (id, load, callback) => {
  req.post({
    key: c.VERIFICATION_FIRST,
    url: `/v1/borrowers/${id}/documents`,
    payload: { documents: [{ ...load }] },
    onSuccess: () => {
      callback();
      toastSuccess('Successfully done KYC');
    },
  });
};

export const putSecondStep = (id, payload, callback) => {
  req.put({
    key: c.VERIFICATION_SECOND,
    url: `/v1/borrowers/profiles/${id}`,
    payload,
    onSuccess: () => {
      callback();
      toastSuccess('Successfully done KYC');
    },
  });
};
