import React, { useState } from 'react';

import PromotionOne from 'assets/images/promotionOne.jpg';

function PromotionalBanners() {
  const [slide, setSlide] = useState(0);

  const handleNextPrev = (action) => () => {
    switch (action) {
      case 'prev':
        if (!slide) return;
        setSlide(slide - 1);
        break;

      default:
        if (slide >= 2) return;
        setSlide(slide + 1);
        break;
    }
  };

  return (
    <div className="relative overflow-hidden lg:rounded-xl">
      <div className="absolute w-full h-full flex z-10">
        <button className="h-full w-1/2" onClick={handleNextPrev('prev')} />
        <button className="h-full w-1/2" onClick={handleNextPrev('next')} />
      </div>
      <div
        className="flex transition-transform duration-300 ease-in"
        style={{ transform: `translateX(-${slide * 100}%)` }}
      >
        <div className="flex-shrink-0 w-full aspect-w-3 aspect-h-1 md:aspect-w-4 lg:aspect-w-5 xl:aspect-w-6 overflow-hidden">
          <img className="absolute inset-0 object-cover" src={PromotionOne} />
        </div>
        <div className="flex-shrink-0 w-full aspect-w-3 aspect-h-1 md:aspect-w-4 lg:aspect-w-5 xl:aspect-w-6 overflow-hidden">
          <img className="absolute inset-0 object-cover" src={PromotionOne} />
        </div>
        <div className="flex-shrink-0 w-full aspect-w-3 aspect-h-1 md:aspect-w-4 lg:aspect-w-5 xl:aspect-w-6 overflow-hidden">
          <img className="absolute inset-0 object-cover" src={PromotionOne} />
        </div>
      </div>
      <div
        className="absolute bottom-2 left-1/2 grid grid-flow-col gap-2 z-10"
        style={{ transform: 'translateX(-50%)' }}
      >
        {[0, 1, 2].map((_, i) => (
          <button
            className={`w-3 h-1 rounded-full ${
              slide === i ? 'bg-primary-800' : 'bg-gray-200'
            }
            `}
            key={i}
            onClick={() => setSlide(i)}
          />
        ))}
      </div>
    </div>
  );
}

export default PromotionalBanners;
